<template>
  <RevContainer v-if="translation" class="bg-static-info-mid">
    <div class="mx-auto flex overflow-hidden p-12">
      <FormattedMessage :definition="translation.definition">
        <template #title>
          <span class="body-1-bold">{{ i18n(translation.title) }}</span>
        </template>
      </FormattedMessage>

      <RevIllustration
        alt=""
        class="ml-20 size-20"
        :height="20"
        src="/img/checkout/flashInfo.svg"
        :width="20"
      />
    </div>
  </RevContainer>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevContainer } from '@ds/components/Container'
import { RevIllustration } from '@ds/components/Illustration'

import { SHIPPING_ALERTS } from '../config'

import translations from './ShippingAlertBanner.translations'

const i18n = useI18n()

const props = defineProps<{
  alert: string
}>()

const translation = computed(() => {
  switch (props.alert) {
    case SHIPPING_ALERTS.COVID19:
      return {
        title: translations.titleCovid19,
        definition: translations.definitionCovid19,
      }
    case SHIPPING_ALERTS.FLOODING:
      return {
        title: translations.titleFlooding,
        definition: translations.definitionFlooding,
      }
    case SHIPPING_ALERTS.PAYMENT_DOWN:
      return {
        title: translations.titlePaymentDown,
        definition: translations.definitionPaymentDown,
      }
    default:
      return null
  }
})
</script>
