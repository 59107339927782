<template>
  <div class="relative text-[1.4rem] md:mb-0">
    <h3 class="heading-3 mb-12 md:mb-24">{{ i18n(title) }}</h3>
    <Transition v-bind="transition" @before-leave="handleBeforeLeave">
      <CheckoutSummaryContent
        class="mb-32"
        data-qa="order-details"
        :discount-price="discountStore.getDeduction"
        :discount-token="discountToken"
        :items="availableItems"
        :price-after-discount="priceAfterDiscount"
        :selected-payment-method="selectedPaymentMethod"
        :service-fee="serviceFee"
        :subtotal="subtotal"
        :swap-offer="swapOffer"
        :tax="tax"
        :total-price-after-buyback="totalPriceAfterBuyback"
        :with-discount="isDiscountApplied"
        :with-extended-legal="withExtendedLegal"
        :with-payment-availability="withPaymentAvailability"
        :with-service-fee="hasServiceFee"
        :with-swap="hasSwapOffer"
        :with-tax="totalPriceIncludesTax"
      />
    </Transition>

    <Transition v-bind="transition" @before-leave="handleBeforeLeave">
      <RevCard v-if="isSwapAdvertisementVisible" class="mb-24 hidden md:flex">
        <SwapBlock
          compact
          :grade-id="0"
          :modal-name="BUYBACK_MODAL_NAMES.SWAP_CHECKOUT_SUMMARY"
          :model="swapAdvertisement.model"
          :price="swapAdvertisement.price"
          product-category=""
          shipping=""
          :swap-offer="swapAdvertisement as Estimation"
          swap-redirection=""
          :swap-status="SWAP_SUCCESS"
          tracking-label="swap_from_cart"
          @confirmation="handleSwapConfirmation"
        />
      </RevCard>
    </Transition>

    <Transition v-bind="transition" @before-leave="handleBeforeLeave">
      <CheckoutSummaryAddressesCard v-if="withAddresses" class="mb-24" />
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { RevCard } from '@ds/components/Card'
import { storeToRefs } from 'pinia'

import { type Estimation } from '~/scopes/buyback/api/adapters/getEstimationSwapPrice.adapter'
import { MODAL_NAMES as BUYBACK_MODAL_NAMES } from '~/scopes/buyback/constants'
import { SWAP_SUCCESS } from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.constants'
import SwapBlock from '~/scopes/buyback/swap/components/SwapBlock/SwapBlock.vue'

import { useCartStore } from '../stores/cartStore'
import { useDiscountStore } from '../stores/discountStore'
import { useSwapStore } from '../stores/swapStore'

import translations from './CheckoutSummary.translations'
import CheckoutSummaryAddressesCard from './CheckoutSummaryAddressesCard.vue'
import CheckoutSummaryContent from './CheckoutSummaryContent/CheckoutSummaryContent.vue'

const i18n = useI18n()
const cartStore = useCartStore()
const discountStore = useDiscountStore()
const swapStore = useSwapStore()
const tracking = useTracking()

const { isApplied: isDiscountApplied, token: discountToken } =
  storeToRefs(discountStore)

const {
  availableItems,
  hasServiceFee,
  priceAfterDiscount,
  serviceFee,
  tax,
  totalGrossPriceWithoutBmFee: subtotal,
  totalPriceIncludesTax,
  totalPriceAfterBuyback,
} = storeToRefs(cartStore)

const {
  hasAdvertisement: hasSwapAdvertisement,
  hasOffer: hasSwapOffer,
  offer: swapOffer,
} = storeToRefs(swapStore)

const props = withDefaults(
  defineProps<{
    withAddresses?: boolean
    withDetails?: boolean
    withPaymentAvailability?: boolean
    withSwapAdvertisement?: boolean
    withExtendedLegal?: boolean
  }>(),
  {
    withAddresses: false,
    withDetails: false,
    withPaymentAvailability: false,
    withSwapAdvertisement: false,
    withExtendedLegal: false,
  },
)

const route = useRoute()

const title = computed(() => {
  return props.withDetails
    ? translations.titleWithDetails
    : translations.titleWithoutDetails
})

const isSwapAdvertisementVisible = computed(
  () => props.withSwapAdvertisement && hasSwapAdvertisement.value,
)

const selectedPaymentMethod = computed(
  () => discountStore.paymentMethod || null,
)

const transition = computed(() => {
  return {
    enterActiveClass:
      'transition duration-1000 ease-out opacity-100 transform translate-y-0 z-20',
    enterFromClass: 'opacity-0 -translate-y-4',
    leaveActiveClass:
      'transition duration-400 ease-out opacity-100 transform translate-y-0 z-10',
    leaveToClass: 'opacity-0 translate-y-4',
  }
})

const swapAdvertisement = computed(() => ({
  model:
    cartStore.availableItems.length === 1
      ? cartStore.availableItems[0].model
      : i18n(translations.swapMultipleItemsTitle),
  price: i18n.price(swapStore.advertisement?.cartPriceWithSwapDiscount ?? ''),
  hasOffer: hasSwapOffer.value,
}))

const handleBeforeLeave = (el: Element) => {
  const element = el as HTMLElement
  /* eslint-disable no-param-reassign */
  element.style.position = 'absolute'
  element.style.top = `${element.offsetTop}px`
  element.style.left = '0'
  element.style.right = '0'
}

const handleSwapConfirmation = async () => {
  await cartStore.fetchCart()

  tracking.trackFunnel(cartStore.trackingData(<string>route.name))
}
</script>
