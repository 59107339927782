<template>
  <div>
    <PaymentIcons class="mt-32" :methods="data || undefined" />

    <PaymentAdvertisement
      v-if="withPaymentAvailability"
      :base-price="bagPrice"
      class="mt-24 flex"
      :payment-methods="data"
      spot="cart"
    />
  </div>
</template>

<script lang="ts" setup>
import { type Price } from '@backmarket/http-api'
import { PaymentAdvertisement } from '@backmarket/nuxt-module-payment/advertising'
import {
  PaymentIcons,
  useMarketPaymentMethodsFor,
} from '@backmarket/nuxt-module-payment/methods'

const props = withDefaults(
  defineProps<{
    withPaymentAvailability: boolean
    listings?: string[]
    bagPrice: Price
    hasInsurance?: boolean
    tokenization?: boolean
  }>(),
  {
    listings: undefined,
    hasInsurance: false,
    tokenization: false,
  },
)

const { data } = await useMarketPaymentMethodsFor({
  listings: () => props.listings || [],
  bagPrice: () => props.bagPrice,
  hasInsurance: () => props.hasInsurance,
  tokenization: () => props.tokenization,
})
</script>
